window._ = require('lodash');
window.userid = '';

try {
    require('bootstrap');
} catch (e) { }

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

window.axios = require('axios');

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */
const AmazonCognitoIdentity = require('amazon-cognito-identity-js');

const poolData = {
    UserPoolId: process.env.MIX_AWS_COGNITO_USER_POOL,
    ClientId: process.env.MIX_AWS_COGNITO_CLIENT_ID,
    Storage: new AmazonCognitoIdentity.CookieStorage({ domain: process.env.MIX_AWS_COGNITO_DOMAIN })
};

const userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);

window.userid = '';

if(userPool.getCurrentUser()) {
    window.userid = userPool.getCurrentUser().username;
}

import cep from 'cep-promise'

window.checkout = (key) => {
    const stripe = Stripe(key);
    const elements = stripe.elements({
        locale: 'pt-BR'
    });

    const cardElement = elements.create('card', {
        hidePostalCode: true
    });

    cardElement.mount('#payment-element');

    const cardHolderName = document.getElementById('name');
    const email = document.getElementById('email');
    const phone = document.getElementById('phone');

    let validCard = false;
    const cardError = document.getElementById('card-errors');

    cardElement.addEventListener('change', function(event) {
        if (event.error) {
            validCard = false;
            cardError.textContent = event.error.message;
        } else {
            validCard = true;
            cardError.textContent = '';
        }
    });

    const form = document.getElementById('payment-form');

    form.addEventListener('submit', async (event) => {
        event.preventDefault();

        const addressStreet = document.getElementById('street').value;
        const addressNumber = document.getElementById('number').value;

        const {paymentMethod, error} = await stripe.createPaymentMethod('card', cardElement, {
            billing_details: {
                name: cardHolderName.value,
                email: email.value,
                phone: `55${phone.value.replace(/\D/g, "")}`,
                address: {
                    postal_code: document.getElementById('postal').value.replace(/\D/g, ""),
                    country: 'BR',
                    state: document.getElementById('state').value,
                    city: document.getElementById('city').value,
                    line1: `${addressStreet}, ${addressNumber}`,
                    line2: document.getElementById('complement').value
                }
            }
        });

        if (error) {
            const messageContainer = document.querySelector('#error-message');
            messageContainer.textContent = error.message;
        } else {
            const pmInput = document.createElement('input');
            pmInput.setAttribute('type', 'hidden');
            pmInput.setAttribute('name', 'payment_method');
            pmInput.setAttribute('value', paymentMethod.id);
            form.appendChild(pmInput);

            const uid = (typeof userid === 'undefined') ? '' : userid;

            const uidInput = document.createElement('input');
            uidInput.setAttribute('type', 'hidden');
            uidInput.setAttribute('name', 'uid');
            uidInput.setAttribute('value', uid);
            form.appendChild(uidInput);

            form.submit();
        }
    });

    // document mask
    const documentMaskOptions = {
        onKeyPress: function (documentNumber, ev, el, op) {
            const masks = ['000.000.000-000', '00.000.000/0000-00'];
            const business = documentNumber.length > 14;

            $('.document').mask(business ? masks[1] : masks[0], op);

            if(business) {
                $('.business').removeClass('hidden');
            } else {
                $('.business').addClass('hidden');
            }
        }
    }

    $('.document').length > 11 ? $('.document').mask('00.000.000/0000-00', documentMaskOptions) : $('.document').mask('000.000.000-00#', documentMaskOptions);

    // phone mask
    $('.phone').mask('(00) 0 0000-0000');

    // postal code mask
    $('.postal').mask('00000-000');

    // postal code
    $('.postal').on('keyup', function () {
        const code = this.value.replace(/\D/g, "");

        if(code.length === 8) {
            cep(code).then(address => {
                $('#postal-code').removeClass('hidden');
                $('#state').val(address.state);
                $('#district').val(address.neighborhood);
                $('#city').val(address.city);
                $('#street').val(address.street);
            });
        }
    });
}
